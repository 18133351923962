import React, { useEffect } from "react";
 
import AppLoader from '@usb-ui-tools/micro-app-loader/dist/cjs/index.js';

import { handleSession, completeSignon, getDotComAKValue, getDotComAppDKey, getManifestOrDeployedPath} from "./utils/DotComUtils";
import * as CommonUtils from "./CommonUtils";
import * as RWC_CommonUtils from "./utils/DotComUtils";
import { DOTCOM_DEFAULT_SETTINGS, DOTCOM_DROPDOWN_REDIRECT_SETTINGS } from "./utils/DotCom_Default_Configs";
import { v4 as uuidv4 } from 'uuid';
import { LOGGER_COMP_LOGIN, LOGGER_EVENT_LOGIN_RWC } from "./Constants";
import logger from '@usb-ui-tools/logger';

function LoginWidgetRWC({redirect}) {

    window.process = {};
    window.process = {
        env: {
            LOG_LEVEL: ''
        }
    };

    useEffect(() => {
        logger.init({
            appId: 'react-auth-login',
            advanced: true,
            eventType: 'AUTH',
            eventSubType: 'LOGIN',
            currentSession:
                typeof window !== 'undefined'
                    ? JSON.parse(sessionStorage.getItem('currentSession'))
                    : null,
            jsLoggerDomain: getManifestOrDeployedPath() || "https://onlinebanking.usbank.com"
        });
        RWC_CommonUtils.logInfo(LOGGER_COMP_LOGIN,LOGGER_EVENT_LOGIN_RWC,'', 'RWC Login request received.');
    }, []);

    sessionStorage.setItem('dotcomRedirection', true);

    // Throw away code post A/B testing or can keep for our metrics
    // Keeping this logic to Quadralics/Analytics team to differentiate DOTCOM flyout vs banner
    if(redirect && redirect === 'true') {
        sessionStorage.setItem('loginWidget', 'static');
    } else {
        sessionStorage.setItem('loginWidget', 'dropDown');
    }

    const AKValue = getDotComAKValue();
    const configEnv = CommonUtils.getConfigEnv();
    const appDynamicsKey = getDotComAppDKey();
    const manifestPath = getManifestOrDeployedPath();
    
    /* Props for the SiteCat - All the below 3 to be passed. If nothing is passed, then the empty string will be sent to SiteCat */
    const appNameForSiteCat = 'OLB';
    const uxNameForSiteCat = 'desktop';
    const clientNameForSiteCat = 'dotcom';

    const header = {
        'Tenant-ID': 'USB', 
        'App-ID': 'DOTCOM', 
        'Channel-ID': 'web', 
        'App-Version': '1.0', 
        'AK': AKValue
    };
    
    const configSettings = (redirect && redirect === 'true') ? DOTCOM_DROPDOWN_REDIRECT_SETTINGS : DOTCOM_DEFAULT_SETTINGS;
   
    // DOTCOM REDIRECTION and include additional policy param to differentiate the flow
    if((redirect && redirect === 'true')) {
        configSettings.transmitConfigs.policyParams.sw = true;
    }
    
    function onTransmitAuthorizationSuccess(response) {
        let decodedToken = CommonUtils.parseJwt(response.token);
        
        /** If the Token has csmId - Redirect to the OLB Standalone for StepUp or Redirect to the Dashboard */
        if (decodedToken?.csmId) {
            const storageItemsObj = {
                "csid": decodedToken.csmId,
                "userId": decodedToken.params.UserId,
                "intId-dotcom": decodedToken.interactionId,
                "corrId-dotcom": decodedToken.correlationId,
                "dotcomRedirection": true,
                "dotcomRM": decodedToken?.params?.rm || false,
                "skipRM": localStorage.getItem('DOTCOMrmtoken') || CommonUtils.getDotComCookie('DOTCOMrmtoken'),
                "olbTimers": sessionStorage.getItem('olbTimers'),
                "loginWidget": 'static', // Keeping this logic to Qualtrics/Analytics team to differentiate DOTCOM flyout vs banner
            };
            // save session storage items and redirect to OLB for OTP stepup
            saveSSOLBRedirection(storageItemsObj);
        } else {
            CommonUtils.updateOlbTimers('DOTCOM','loginAuthSuccess');
            handleSession();
            completeSignon(response);
        }
    }

    function onTransmitAuthorizationFailure() {
    }

    function onLoginModuleLoaded() {
        RWC_CommonUtils.logInfo(LOGGER_COMP_LOGIN,LOGGER_EVENT_LOGIN_RWC,'', 'RWC Login load completed.');
        console.log("Login Module loaded on Dotcom");
        document.querySelector('button.loginButton')?.classList?.remove("widgetLoading");
        document.querySelector(".ghp-page .nav-utility-bar.top-bar")?.classList?.add("login-open", "widget");
        document.querySelector("button.loginButton")?.setAttribute("aria-expanded", true);
        document.querySelector("button.loginButton .closeLogin")?.classList?.remove("hide");
        if(!document.querySelector(".login-widget-wrapper .is-fallBack")){
            document.querySelector(".login-widget-wrapper .fallback-text")?.classList?.add("is-hidden");
        }
        if(document.querySelector('auth-login-rwc[redirect="false"]')){
            document.querySelector("button.loginButton")?.setAttribute('style','display:block !important'); 
            document.querySelector('#onlinebankingURLDesktop')?.setAttribute('style', 'display:none');
        }
    }

    function onFieldValidationCallback(error, event) {
        const storageItemsObj = {
            "dotcomError": JSON.stringify(error),
            "dotcomRedirection": true,
            "intId-dotcom": sessionStorage.getItem('InteractionID') || uuidv4(),
            "userId": sessionStorage.getItem('userId'),
            "dotcomSiteCatEvent": JSON.stringify(event),
        };
        // save session storage items and redirect to OLB & display respective errors
        saveSSOLBRedirection(storageItemsObj);    
    }

    async function saveSSOLBRedirection(storageItemsObj) {
        var laform = document.createElement('form');
        laform.id = "laform";
        laform.method = "POST";
        laform.action = configSettings.urls?.routerAppURL;
        
        var storageItems = document.createElement("INPUT");
        var redirectUrl = document.createElement("INPUT");

        storageItems.name = "storageItems";
        storageItems.value = JSON.stringify(storageItemsObj);
        storageItems.type = 'hidden';
        redirectUrl.name = "redirectUrl";
        redirectUrl.value = configSettings.urls.loginStandaloneURL;
        redirectUrl.type = 'hidden';

        laform.appendChild(storageItems);
        laform.appendChild(redirectUrl);

        document.body.appendChild(laform);
        laform.submit();
    }

    window.custHubLandingPage = configSettings.urls?.dashboard?.CUST_HUB_LANDING_PAGE;
    window.routerAppURL = configSettings.urls?.routerAppURL;
    window.bauCustomerDashboardUrl = configSettings.urls?.bauCustomerDashboardUrl;
    window.routerappShellCDUrl = configSettings.urls?.routerappShellCDUrl;
    window.bauAccountDashboardUrl = configSettings.urls?.bauAccountDashboardUrl;
    window.routerappShellADUrl = configSettings.urls?.routerappShellADUrl;
  
    return (
        <div id={`micro-loader-login-${uuidv4()}`}>
        <AppLoader
            elementRef={ React.createRef()}
            id={`rwc-micro-app-${uuidv4()}`}
            isDomainPrefixedToManifestPath = {false}
            manifestPath={`${manifestPath}/auth/login/wmf/v1/manifest.json`}
            namespace="USBAuthLoginModule"
            appProps={{
                configApiHeaders: header,
                configSettings: configSettings,
                onAuthorizationSuccess: onTransmitAuthorizationSuccess,
                onAuthorizationFailure: onTransmitAuthorizationFailure,
                appNameForSiteCat: appNameForSiteCat,
                uxNameForSiteCat: uxNameForSiteCat,
                clientNameForSiteCat: clientNameForSiteCat,
                isOLB: false,
                isUMDFormat: false,
                isAppDEnabled: true,
                isReportingEnabled: true,
                isIovationEnabled: true,
                isJSLoggerEnabled: true,
                configApiURL: `https://${configEnv}api.usbank.com/authentication/customer-auth/app-config/v1/config`,
                appDKey: appDynamicsKey,
                idShieldQuestion: false,
                isTux: false,
                isHeaderRequired: false,
                isFooterRequired: false,
                onFieldValidationCallback: onFieldValidationCallback,
                isDotCom: (redirect && redirect === 'true') || false,
                isResetPasswordEnabled: true,
                onLoginModuleLoaded: onLoginModuleLoaded
            }}
        />
        </div>
    );
}
 
export default LoginWidgetRWC;
